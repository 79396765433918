import './App.css';

function App() {
  return (
    <div className="base">
      <div className="stars"></div>
        <div className="stars2"></div>
        <div className="stars3"></div>
      <div className="Title" onselectstart="return false">
        <span>4rmy</span>
        <br></br>
        <span>Bringing The Cheats</span>
      </div>
    </div>
  );
}

export default App;
